import type { MaintenanceStatus } from './interface';
import { arcsiteLogo, serverMaintenance } from './assets';

export function MaintenanceGuardView(props: MaintenanceStatus) {
  const { is_maintenance } = props;
  if (!is_maintenance) return null;

  const endData = new Date(props.end_time * 1000).toLocaleString();
  return (
    <div className="fixed z-50 inset-0 flex flex-col justify-center items-center bg-white-100 bg-background">
      <div className="absolute top-5 left-5">
        <img src={arcsiteLogo} alt="logo" className="h-10" />
      </div>
      <img
        src={serverMaintenance}
        alt="server-maintenance"
        className="h-[324px]"
      />
      <h1 className="text-[32px] font-semibold h-[40px] my-[24px]">
        Server Maintenance
      </h1>
      <p className="text-[16px] px-4">
        Sorry, we are performing some maintenance until around{' '}
        <span className="font-semibold text-[#E3571C]">{endData}</span>
      </p>
      <p className="text-[16px]">
        Please try again later.{' '}
        <a
          className="text-[#398AE7] cursor-pointer"
          href="https://www.arcsite.com/contact"
        >
          Contact Us.
        </a>
      </p>
    </div>
  );
}
