import { callPostApi } from '@arcsite/api';

export type PaymentSettings = {
  support_ach: boolean;
  support_credit_card: boolean;
};

export type ProjectPaymentSettings = {
  payment_settings: PaymentSettings | null; // 如果project上没有配置过payment_settings返回null
  support_ach: boolean;
  support_credit_card: boolean;
  can_update_payment_settings: boolean; // 是否可以更新支付设置
  can_update_support_ach: boolean; // 是否可以更新支持ACH支付
  can_update_support_credit_card: boolean; // 是否可以更新支持信用卡支付
};

export async function getProjectPaymentSettings(project_id: string) {
  return callPostApi<ProjectPaymentSettings>(
    'user/service/my/project/get_payment_settings/',
    {
      project_id,
    }
  );
}

export async function updateProjectPaymentSettings(
  project_id: string,
  settings: { support_ach: boolean; support_credit_card: boolean }
) {
  await callPostApi('user/service/my/project/update_payment_settings/', {
    project_id,
    ...settings,
  });
}
