import { useMaintenanceStatus } from '@/api';
import { MaintenanceGuardView } from '@arcsite/shared/maintenance-guard';

export function MaintenanceGuard() {
  const { data: maintenanceData } = useMaintenanceStatus();
  if (maintenanceData) {
    return <MaintenanceGuardView {...maintenanceData} />;
  }
  return null;
}
